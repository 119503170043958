import {
  Header,
  Footer,
  ButtonSubmit,
  ButtonBack,
  ScreenLoading
} from '@components'
import { campaignService } from '@services'
import { Campaign } from '@utils'
import { LANGUAGES } from "@i18n";
import {
  IS_NOT_ANIMATION,
  IS_ANIMATION,
  WIN_FLAG,
  LOSE_FLAG,
  DELAY_LOTTERY_GAME,
  NOT_DELAY_LOTTERY_GAME,
  HTTP_CODE_OK,
  HTTP_CODE_GET_SURVEY_LINK_FAILED,
  HTTP_CODE_LOTTERY_UUID_NOT_EXIST,
  HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG,
  DEFAULT_BACKGROUND_LOTTERY_GAME,
  FLOW_TYPE_REDIRECT } from '@constants'

export default {
  name: 'LotteryPlay',
  components: {
    Header,
    Footer,
    ButtonSubmit,
    ButtonBack,
    ScreenLoading
  },
  data() {
    return {
      campaignSettings: {},
      winFlag: null,
      itemName: '',
      winItemImageUrl: '',
      winItemTitle: '',
      winItemContent: '',
      winReceiveItemUrl: '',
      campaignUrl: '',
      campaignUrlName: '',
      isFetchingPage: false,
      isRedirect: false
    }
  },
  methods: {
    lotteryInitial: async function() {
      this.isFetchingPage = true

      // check has lotteryCampaign
      const lotteryCampaign = Campaign.getLotteryCampaign(this.$route.params.lotteryUuid);
      if (lotteryCampaign) {
        this.winFlag = LOSE_FLAG
        this.campaignUrl = lotteryCampaign.campaign_url
        this.campaignUrlName = lotteryCampaign.campaign_url_name
        this.campaignSettings = lotteryCampaign?.campaign_settings
        this.isRedirect = true

        this.isFetchingPage = false

        Campaign.clearLotteryCampaign(this.$route.params.lotteryUuid)
      } else {
        await campaignService
          .lotteryPlay({ lottery_uuid: this.$route.params.lotteryUuid, campaign_url_name: this.$route.params.campaignUrlName })
          .then( async (res) => {
            const data = res.data
            if (data && res.status === HTTP_CODE_OK) {
              Campaign.clearCampaign()
              Campaign.setCampaign(data)
              this.campaignSettings = data.campaign_settings
              
              // check campaign flow redirect when win
              if (data.flow === FLOW_TYPE_REDIRECT && data.win_flag === LOSE_FLAG) {
                this.lotteryPlay(data, NOT_DELAY_LOTTERY_GAME)
              } else if (data.survey_link) {
                window.location.href = data.survey_link
              } else if (data.is_animation === IS_ANIMATION) {
                this.lotteryPlay(data, DELAY_LOTTERY_GAME)
              } else if (data.is_animation === IS_NOT_ANIMATION) {
                this.lotteryPlay(data, NOT_DELAY_LOTTERY_GAME)
              }
              this.isFetchingPage = false
            } else if (data.status === HTTP_CODE_CAMPAIGN_URL_NAME_LOTTERY_UUID_WRONG) {
              this.$router.push({
                name: 'campaign.error.404',
                replace: true
              })
            } else if (data.status === HTTP_CODE_GET_SURVEY_LINK_FAILED) {
              this.$router.push({
                name: 'campaign.error.404',
                replace: true
              })
            } else if (data.status === HTTP_CODE_LOTTERY_UUID_NOT_EXIST) {
              this.$router.push({
                name: 'campaign.error.404',
                replace: true
              })
            } else {
              this.$router.push({
                name: 'campaign.home',
                params: { campaignUrlName: data.data.campaign_url_name, campaignUrl: data.data.campaign_url },
                replace: true
              })
            }
          })
      }
    },
    calcLotteryResult(data) {
      if (data.win_flag === WIN_FLAG) {
        this.winFlag = WIN_FLAG
        this.itemName = data.campaign_gift.item_name
        this.winItemImageUrl = data.campaign_gift.win_item_image_url
        this.winItemTitle = data.campaign_gift.win_item_title
        this.winItemContent = data.campaign_gift.win_item_content
        this.winReceiveItemUrl = data.campaign_gift.gift_url
      } else if (data.win_flag === LOSE_FLAG) {
        this.winFlag = LOSE_FLAG
        this.campaignUrl = data.campaign_url
        this.campaignUrlName = data.campaign_url_name
      }
    },
    lotteryPlay(data, delay = 0) {
      if (delay === DELAY_LOTTERY_GAME) {
        setTimeout(() => (
          this.calcLotteryResult(data)
        ), DELAY_LOTTERY_GAME)
      } else {
        this.calcLotteryResult(data)
      }
    },
    receiveGift: async function() {
      this.isFetchingPage = true

      if (this.winReceiveItemUrl) {
        window.location.href = this.winReceiveItemUrl
      } else {
        await campaignService
          .createGiftUrl({ lottery_uuid: this.$route.params.lotteryUuid, campaign_url_name: this.$route.params.campaignUrlName })
          .then((res) => {
            if (res.status === HTTP_CODE_OK) {
              window.location.href = res.data.gift_url
            } else {
              this.$router.push({
                name: 'campaign.error',
                replace: true
              })
            }
          })
      }
    },
    getExtension(filepath) {
      return filepath?.substring(filepath.lastIndexOf('.') + 1, filepath.length) || filepath
    }
  },
  computed: {
    createSetAppContainerStyles() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': this.campaignSettings.preset_bg_color,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
        this.$i18n.locale = LANGUAGES[Campaign.getLanguage().language_id];
      }
    },
    createSetAppContainerStylesForAnimation() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': DEFAULT_BACKGROUND_LOTTERY_GAME,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
        this.$i18n.locale = LANGUAGES[Campaign.getLanguage().language_id];
      }
    }
  },
  mounted() {
    this.lotteryInitial()
  },
  destroyed() {
    this.isFetchingPage = false
  }
}
